.page {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;

  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
}

.container {
  text-align: center;
  width: 32rem;
  height: 45rem;
  margin: auto 0 auto 0;

  background-color: var(--background-container);
  box-shadow: 6px 8px 4px rgba(0, 0, 0, 0.25);
  border-radius: 1rem;
}

.title {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 600;
  font-size: 4rem;
  line-height: 5.745rem;
  color: var(--dark-blue);

  margin-top: 7rem;
}

.logo {
  width: 6rem;
  height: 3.1rem;
  margin-top: -1rem;
  margin-bottom: 7rem;
}

.button {
  width: 80%;
  margin-top: 1rem;
}
